<template>
  <v-data-table
    :headers="headers"
    :items="items"
    class="elevation-0"
    :search="search"
    mobile-breakpoint="960"
    @click:row="(item) => $emit('edititem', item)"
  >
    <template v-slot:[`item.actions`]="{ item }">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="mr-2"
            color="green"
            @click.stop="$emit('edititem', item)"
            v-bind="attrs"
            v-on="on"
          >
            mdi-pencil
          </v-icon>
        </template>
        <span>{{ $vuetify.lang.t("$vuetify.edit") }}</span>
      </v-tooltip>
      <v-tooltip top v-if="permissionCheck('del')">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            @click.stop="$emit('deleteitem', item)"
            color="red"
            v-bind="attrs"
            v-on="on"
          >
            mdi-delete
          </v-icon>
        </template>
        <span>{{ $vuetify.lang.t("$vuetify.delete") }}</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.note`]="{ item }">
      {{ checkStrLength(item.note) }}
      <span class="blue--text" v-if="item.note.length > 30">.....more</span>
    </template>

    <template v-slot:[`item.address`]="{ item }">
      {{ checkStrLength(item.address) }}
      <span class="blue--text" v-if="item.address.length > 30">.....more </span>
    </template>

    <template v-slot:[`item.branch`]="{ item }">
      {{ checkStrLength(item.branch) }}
      <span class="blue--text" v-if="item.branch.length > 30">.....more </span>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "tableCRUD",
  props: { headers: Array, items: Array, search: String },
  methods: {
    checkStrLength(item) {
      if (item.length > 30) {
        return item.substring(0, 30);
      } else {
        return item;
      }
    },
  },
};
</script>

<style></style>
